import { USER_ROLE } from "src/types/user-management";

export const API_ROUTES = {
  BASE_URL: "/",
  AUTH: {
    LOGIN: "/admin-api/authenticate",
    PROFILE: "/admin-api/users/_self",
  },
  USERS: {
    GET_DETAILS: (id: string) => `users/${id}`,
    PROFILE: "",
  },
  RIDERS: {
    ROOT: "admin-api/riders",
    GET_DETAILS: (id: string) => `admin-api/riders/${id}`,
    EXPORT: `admin-api/riders/riders-csv`,
    CHANGE_PASSWORD: (id: string) => `admin-api/riders/${id}/password`,
    SET_AVATAR: (id: string) => `admin-api/riders/${id}/avatar`,
    BULK_INSERT: `admin-api/riders/bulk-create-by-file`,
    INACTIVE_RIDERS: `admin-api/dashboard/inactive-riders`,
    INACTIVE_EXPORT: `admin-api/dashboard/inactive-riders-export`,
    TO_DISABLED_RIDER: (id: string) => `admin-api/riders/${id}/disable`,
    TO_ENABLED_RIDER: (id: string) => `admin-api/riders/${id}/enable`,
    IDLE_RIDERS: `admin-api/dashboard/idle-riders`,
    UNREACHABLE_RIDERS: `admin-api/dashboard/unreachable-riders`,
  },
  GROUPS: {
    ROOT: "admin-api/zone-groups",
    GET_DETAILS: (id: string) => `admin-api/zone-groups/${id}`,
    UPDATE: (id: string) => `admin-api/zone-groups/${id}`,
    GET_ZONES_GROUP: (id: string) => `admin-api/zones/get-by-group/${id}`,
    EXPORT: `admin-api/zone-groups/zone-groups-csv`,
  },
  TARGET_GROUPS: {
    ROOT: "paging-api/admin/target-groups",
    ACTIVES: "paging-api/admin/target-groups/actives",
    GET_DETAILS: (id: string) => `paging-api/admin/target-groups/${id}`,
    UPDATE: (id: string) => `paging-api/admin/target-groups/${id}`,
    ENABLE: (id: string) => `paging-api/admin/target-groups/${id}/enable`,
    DISABLE: (id: string) => `paging-api/admin/target-groups/${id}/disable`,
    GET_BY_RIDER_ID: (riderId: string) =>
      `paging-api/admin/target-groups/rider/${riderId}`,
    GET_TARGET_GROUP_RIDERS: (targetGroupId: string) =>
      `paging-api/admin/target-groups/${targetGroupId}/riders`,
  },
  ZONES: {
    ROOT: "admin-api/zones",
    RIDERS: (id: string | number) => `admin-api/zones/${id}/riders`,
    GET_DETAILS: (id: string) => `admin-api/zones/${id}`,
    UPDATE: (id: string) => `admin-api/zones/${id}`,
    EXPORT: `admin-api/zones/zones-csv`,
    CREATE_ACCOUNT: (id: string) => `admin-api/zones/create-user/${id}`,
    UPDATE_ACCOUNT: (id: string) => `admin-api/zones/update-user/${id}`,
    DISABLE_OTP: (id: string) => `admin-api/zones/${id}/disable-otp`,
    ENABLE_OTP: (id: string) => `admin-api/zones/${id}/enable-otp`,
  },
  RIDER_LOCATIONS: {
    ROOT: "admin-api/rider-locations",
  },
  ATTENDANCE_LOG: {
    ROOT: `admin-api/attendances`,
    SUMMATION: `admin-api/attendances/summation`,
    AGGREGATE: `admin-api/attendances/aggregates`,
    TIME_LINE: `admin-api/attendances/events`,
    REPORT: `admin-api/attendances/aggregates`,
    EXPORT: `admin-api/attendances/aggregates-csv`,
  },
  VEHICLES: {
    ROOT: `admin-api/vehicles`,
    GET_DETAILS: (id: string) => `admin-api/vehicles/${id}`,
    EXPORT: `admin-api/vehicles/vehicle-csv`,
    ASSIGN_VEHICLE: (id: string) => `admin-api/vehicles/${id}/rider`,
    UNASSIGN_VEHICLE: (riderId: string) =>
      `admin-api/vehicles/rider/${riderId}`,
    BULK_INSERT: `admin-api/vehicles/bulk-create-by-file`,
  },
  WORKING_STATUS: {
    ROOT: `admin-api/working-status`,
    GET_DETAILS: (id: string) => `admin-api/working-status/${id}`,
    EDIT: (riderId: string, workingStatusId: number) =>
      `/admin-api/riders/${riderId}/working-status/${workingStatusId}`,
  },
  DASHBOARD: {
    STATISTICS: `admin-api/dashboard/statistics`,
    TOPZONESPERRIDERS: `admin-api/dashboard/top-zones-per-riders`,
    TOPZONESPERRTIME: `admin-api/dashboard/top-zones-per-check-in-time`,
  },
  NOTIFICATION: {
    GET_NOTIFICATION_COUNT: `admin-api/notifications/new-notification-count`,
    GET_NOTIFICATIONS: `admin-api/notifications`,
    READ_NOTIFICATIONS: `admin-api/notifications`,
  },
  ORDERS: {
    GET_ONGOING_ORDERS: `paging-api/admin/orders`,
    GET_ELIGIBLE_RIDERS: (id: string) =>
      `paging-api/admin/orders/${id}/eligible-riders`,
    GET_ORDER_HISTORIES: `paging-api/admin/orders/histories`,
    GET_ORDERS_BY_STATUS_COUNT: `paging-api/admin/orders/orders-by-status-count`,
    GET_ORDERS_BY_STATUS: `paging-api/admin/orders/orders-by-status`,
    GET_ORDER_BY_ID: (id: string) => `paging-api/admin/orders/get-by-id/${id}`,
    UPDATE_ORDER: (id: string) => `paging-api/admin/orders/update/${id}`,
    UPLOAD_ORDER_RECEIPT: `paging-api/admin/orders/upload-image`,
    CREATE_ORDER_BY_ADMIN: `paging-api/admin/orders/check-out-by-admin`,
    GET_ADMIN_ORDER: (id: string) => `paging-api/admin/orders/get-by-id/${id}`,
    UPDATE_ORDER_BY_ADMIN: (id: string) =>
      `paging-api/admin/orders/update-admin-order/${id}`,
    EXPORT_RIDER_ORDERS_REPORT: `paging-api/admin/orders/rider-order-delivered-csv`,
  },
  USER_MANAGEMENT: {
    CREATE_USER: `admin-api/users`,
    GET_USERS: `admin-api/users/get-users`,
    CHECK_USERNAME_UNIQUENESS: `admin-api/users/check-username`,
    GET_USER: (id: string) => `admin-api/users/get-user/${id}`,
    UPDATE_USER: (id: string) => `admin-api/users/${id}`,
    ENABLE_USER: (id: string) => `admin-api/users/${id}/enable`,
    DISABLE_USER: (id: string) => `admin-api/users/${id}/disable`,
    RESET_PASSWORD: `admin-api/users/reset-password`,
  },
  THIRD_PARTIES: {
    GET_ALL: `paging-api/admin/third-parties`,
    GET_INFO: (id: string) => `paging-api/admin/third-parties/${id}`,
    CREATE: `paging-api/admin/third-parties`,
    UPDATE: (id: string) => `paging-api/admin/third-parties/${id}`,
    ENABLE_ITEM: (id: string) => `paging-api/admin/third-parties/${id}/enable`,
    DISABLE_ITEM: (id: string) =>
      `paging-api/admin/third-parties/${id}/disable`,
        ENABLE_OTP: (id: string) => `paging-api/admin/third-parties/${id}/enable-otp`,
    DISABLE_OTP: (id: string) =>
      `paging-api/admin/third-parties/${id}/disable-otp`,
    GENERATE_API_KEY: (id: string) =>
      `paging-api/admin/third-parties/${id}/generate-token`,
  },
};
